// ------------------------------------------------------------ COLOURS
export const colours = {
  alert: `#C72025`,
  urgent: `#C72025`,
  urgentContainer: `#FDEBEF`,
  important: `#FFA24B`,
  importantContainer: `#FFE8D2`,
  positive: `#35C680`,
  positiveContainer: `#D6FFEB`,
  buttonBlue: `#25A8C5`,
  buttonGreen: `#25C578`,
  buttonRed: `#EE2929`,
  buttonRedAlt: `#EE2959`,
  inputOutline: `#9B9B9B`,
  lightGrey: `#DADADA`,
  // new colours
  neutral100: '#FFFFFF',
  neutral200: '#FCFCFC',
  neutral300: '#F8F8F8',
  neutral400: '#EFEFEF',
  neutral500: '#DADADA',
  neutral600: '#AEAEAE',
  neutral700: '#9B9B9B',
  neutral800: '#7A7A7A',
  neutral900: '#000000',
  green100: '#A0F0C9',
  green200: '#3ADD8F',
  green300: '#25C578',
  green400: '#22AD6A',
  blue100: '#A6E1EE',
  blue200: '#69CEF6',
  blue300: '#25A8C5',
  blue400: '#1B9AB6',
  orange100: '#FFE8D2',
  orange200: '#FFA24B',
  orange300: '#FF922A',
  orange400: '#EE6B1A'
}

// ------------------------------------------------------------ TRANSITIONS
export const transitions = {
  fast: `0.1s ease`,
  default: `0.2s ease`,
  slow: `0.3s ease`,
  elastic: `0.2s cubic-bezier(0.5, 0.1, 0.3, 1.4)`,
}

// ------------------------------------------------------------ BOX SHADOWS
export const boxShadows = {
  minimal: `box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);`,
  default: `box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);`,
  hover: `box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.25);`,
}

// ------------------------------------------------------------ BOX SHADOWS
export const containers = {
  default: `
    background: white;
    border-radius: 6px;
    padding: 52px 52px 66px 52px;
    box-shadow: 0 0 5px 0 rgba(180, 180, 180, 0.25);
  `
}