import { ChangeEvent, FunctionComponent } from 'react';
import styled from 'styled-components';

import { Slider } from '../Slider/Slider';
import { TextArea } from '../TextArea/TextArea';
import { Button, ButtonColour, ButtonIcon, ButtonType } from '../Button/Button';
import { ScriptData } from '../ScriptData/ScriptData';
import { QueueItemTypes } from 'sparrowhub-client-axios';

import deleteIcon from '../../assets/images/icons/TrashNew.svg';

export const queueItemOptions: Array<any> = [
  {
    label: 'Paper Script',
    code: QueueItemTypes.PaperScript
  },
  {
    label: 'eRx Paper Script',
    code: QueueItemTypes.ErxPaperScript
  },
  {
    label: 'E-Script',
    code: QueueItemTypes.Escript
  },
  {
    label: 'General Consult',
    code: QueueItemTypes.GeneralConsult
  },
  {
    label: 'Vaccination',
    code: QueueItemTypes.Vaccination
  },
  {
    label: 'Medical Certificate',
    code: QueueItemTypes.MedicalCertificate
  },
  {
    label: 'Scope Of Practice',
    code: QueueItemTypes.ScopeOfPractice
  }
]


type ScriptInformationProps = {
  script: any
  handleChange?: Function
  handleDelete?: Function
}

export const ScriptInformation: FunctionComponent<ScriptInformationProps> = ({ script, handleChange, handleDelete }) => {
  return (
    <StyledScriptInformation className="ScriptInformation">
      <button className="deleteButton buttonShadow" onClick={() => { if (handleDelete) handleDelete(script.id) }}>
        <img src={deleteIcon} alt="Delete item" />
      </button>
      <h5 className="heading5">{queueItemOptions.find(option => option.code === script.type).label}</h5>
      <ScriptData script={script} />
      <div className="ScriptInformation_actions">
        <div className="ScriptInformation_toggles">
          <Slider label="Allow Brand Substitution" state={script.substitutionEnabled} onChange={() => { if (handleChange) handleChange(script.id, { substitutionEnabled: !script.substitutionEnabled }) }} reverse />
        </div>
        {/* <Slider label="Script Notes Added" labelFalse="No Script Notes Added" state={script.noteEnabled} onChange={() => { if (handleChange) handleChange(script.id, { noteEnabled: !script.noteEnabled }) }} /> */}
        {script.noteEnabled &&
          <TextArea id="notes-input" small grey placeholder="Add script notes here..." value={script.note} maxLength={512} onChange={(e: ChangeEvent) => { if (handleChange) handleChange(script.id, { note: (e.target as HTMLTextAreaElement).value }) }} />
        }
      </div>
    </StyledScriptInformation>
  );
}

const StyledScriptInformation = styled.div`
  .ScriptInformation_actions {
    border-top: 1px solid #DADADA;

    .ScriptInformation_toggles {
      display: flex;
      margin: 11px 0 10px 0;
    }


    .TextArea {
      margin-top: 13px;
      margin-bottom: 0;
    }

    .Button_RedAlt {
      margin-top: 21px;
    }
  }
`