import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { QueueStatuses, UpdateQueueStatusRequestInner } from "sparrowhub-client-axios";

import { useApi } from '../../context/ApiProvider';
import { SparrowHubApiInterface } from 'sparrowhub-client-axios';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { DefaultErrorMessage } from '../DefaultErrorMessage/DefaultErrorMessage';


type ScriptQueueCollectAllModalProps = {
  queueElementIds: Array<number>
  show: boolean
  onRefresh: Function
  onClose: Function
}

export const ScriptQueueCollectAllModal: FunctionComponent<ScriptQueueCollectAllModalProps> = ({ queueElementIds, show, onRefresh, onClose }) => {
  const { api }: {api: SparrowHubApiInterface } = useApi();
  
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // methods
  const successDuration = 3000;
  const handleCollectAll = async (recaptcha: string): Promise<any> => {
    setErrorMessage('');
    const requestBody: Array<UpdateQueueStatusRequestInner> = queueElementIds.map(id => {
      return {
        id,
        status_code: QueueStatuses.Collected
      }
    });

    console.log(requestBody)

    api.updateQueueStatus(requestBody)
      .then(response => {
        setIsLoading(false);
        setShowSuccess(true);
        onRefresh();
        setTimeout(() => {
          handleClose();
        }, successDuration);
      })
      .catch(error => {
        console.log('Error updating Queue Elements')
        console.log(error);
        setIsLoading(false);
        let errorMessage = '';
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        setErrorMessage(`Error updating Queue tickets${errorMessage === '' ? '.' : `: ${errorMessage}`}`);
      })
  }

  const transitionDuration = 300;
  const handleClose = (): void => {
    setTimeout(() => {
      setShowSuccess(false);
    }, transitionDuration);

    if (onClose) onClose();
  }

  return (
    <StyledScriptQueueCollectAllModal className="ScriptQueueCollectAllModal">
      <Modal show={show}>
        {!showSuccess &&
          <>
            <div className="divider" style={{ paddingBottom: '25px' }}>
              <Heading heading={`This will mark ${queueElementIds.length} ${queueElementIds.length === 1 ? 'ticket' : 'tickets'} as collected.`} />
            </div>

            <p className="bold">These tickets will move to "Archived Tickets". This action cannot be undone.</p>

            {errorMessage &&
              <div style={{ marginTop: '30px', marginBottom: '-20px' }}>
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br/><DefaultErrorMessage /></p>
                </Alert>
              </div>
            }
            <Button type={ButtonType.Primary} tall text="Mark as Collected" onClick={handleCollectAll} loading={isLoading} />
            <Button type={ButtonType.Secondary} text="Cancel" onClick={handleClose} />
          </>
        }
        {showSuccess &&
          <Alert type={AlertType.PositiveSecondary} successModal>
            <p>All tickets were successfully marked as collected and can be found in<span className="extrabold">Archived Tickets</span>.</p>
          </Alert>
        }
      </Modal>
    </StyledScriptQueueCollectAllModal>
  );
}

const StyledScriptQueueCollectAllModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    .ScriptQueueCollectAllModal_details {
      margin-top: 28px;
    }

    .ScriptQueueCollectAllModal_grid {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      grid-gap: 25px 14px;
      gap: 25px 14px;
      margin: 50px 0;

      .Button {
        width: 100%;
        height: 128px;
        margin: 0 !important;
      }
    }
  }

  .Button_primary {
    margin-top: 50px;
    margin-bottom: 23px;
  }
`