import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { QRCodeSVG } from 'qrcode.react';

import tagCheckIcon from '../../assets/images/tags/Check.svg';
import tagCrossIcon from '../../assets/images/tags/Cross.svg';

type ScriptDataProps = {
  script: any
  docket?: boolean
}

export const ScriptData: FunctionComponent<ScriptDataProps> = ({ script, docket }) => {
  const patientName = (): string => {
    if (script.prescription !== undefined) {
      return script.prescription.patient_name || script.prescription.prescription_patient_name;
    } else {
      return script.prescription_patient_name;
    }
  }

  const drugName = (): string => {
    if (script.prescription) {
      return script.prescription.drug_name;
    } else {
      return script.prescription_name;
    }
  }

  const scriptToken = (): string => {
    if (script.token) {
      return script.token;
    } else {
      return script.prescription_token;
    }
  }

  const scriptRepeats = (): number | string => {
    if (script.prescription) {
      return script.prescription.repeats;
    } else {
      return script.prescription_repeats;
    }
  }

  const brandSubstitution = (): number | string => {
    if (script.prescription) {
      return script.prescription.brand_substitution_permitted;
    } else {
      return script.brand_substitution_permitted;
    }
  }

  const note = (): number | string => {
    if (script.prescription) {
      return script.prescription.queue_item_note;
    } else {
      return script.queue_item_note;
    }
  }

  return (
    <StyledScriptData className="ScriptData">
      {!docket ?
        // ScriptInformation version
        <div className="ScriptData_header">
          {script.type === 'escript' &&
            <div className="qrcode">
              <QRCodeSVG value={scriptToken()} size={61} />
            </div>
          }
          <div className="ScriptData_details">
            <p className="bold header">{drugName() || scriptToken()}</p>
            <p className="details">
              <span className="bold">Token:</span> {scriptToken()}<br />
              <span className="bold">Name:</span> {patientName() || '-'}<br />
              <span className="bold">Supply:</span> {scriptRepeats() !== null ? `${scriptRepeats()} remaining` : '-'}
            </p>
          </div>
        </div>
        :
        // ScriptDocket version
        <div className="ScriptData_header docket">
          {script.queue_item_type_code === 'escript' &&
            <div className="qrcode">
              <QRCodeSVG value={scriptToken()} size={256} />
            </div>
          }
          <div className="ScriptData_details">
            <p className="bold patientName">{patientName()}</p>
            <p className="bold drugName">{drugName()}</p>
            <p className="extrabold brandSubstitution">
              {brandSubstitution() ? 'Allow Brand Substitution' : 'No Brand Substitution'}
            </p>
            <p className="details">
              <span className="bold">Token:</span> {scriptToken()}<br />
              {scriptRepeats() &&
                <>
                  <span className="bold">Supply:</span> {scriptRepeats()} remaining
                </>
              }
            </p>
            {note() &&
              <div className="notes">
                <p className="bold heading">Script Notes</p>
                <p className="content">{note()}</p>
              </div>
            }
          </div>
        </div>
      }
    </StyledScriptData>
  );
}

ScriptData.defaultProps = {
  docket: false
}

const StyledScriptData = styled.div`
  .ScriptData_header {
    display: flex;
    gap: 13px;

    .ScriptData_details {
      width: 100%;
      padding-bottom: 8px;

      p {
        line-height: 1.25rem;
      }
      .header {
        font-size: 0.875rem; // 14px
        margin: 0 0 3px 0;
      }
      .details {
        font-size: 0.75rem; // 12px
        margin: 0;
        column-count: 2;
      }
    }

    &.docket {
      flex-direction: column;

      .ScriptData_details {
        .patientName {
          font-size: 2.25rem; // 36px
          line-height: 1;
          margin: 16px 0 28px 0;
        }

        .drugName {
          font-size: 1.5rem; // 24px
          line-height: 1;
          margin: 24px 0;
        }
        
        .brandSubstitution {
          font-size: 0.75rem; // 12px
          line-height: 1;
          margin: 24px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
        }

        .details {
          font-size: 1.125rem; // 18px
          margin: 0;
          line-height: 2;
          column-count: 1;
        }

        .notes {
          margin: 30px auto 0 auto;
          border: 1px solid black;
          border-radius: 10px;
          padding: 6px 20px;
          width: 90%;

          .heading {
            margin-bottom: 0;
            line-height: 1.625rem !important; // 26px
            font-size: 1.625rem !important; // 26px
          }
          
          .content {
            margin-top: 1rem;
            line-height: 1.625rem !important; // 26px
            font-size: 1.625rem !important; // 26px
          }
        }
      }
    }
  }
`