import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { IOrders } from "../../types/IOrders";
import { IUser } from "../../types/IUsers";
import { ILocation, ILocations } from "../../types/ILocations";
import { IScriptQueueConfig, defaultScriptQueueConfig } from "../../types/IScriptQueueConfig";

import { Cart, GetCurrentPartnerResponse, QueueElement, QueueElementWithItems } from 'sparrowhub-client-axios';

import { ApiProvider } from '../../context/ApiProvider';
import { DummyDataProvider } from '../../context/DummyDataProvider';
import { HomePage } from '../../pages/HomePage';
import { AdminPage } from '../../pages/AdminPage';
import { OrdersPage } from '../../pages/OrdersPage';
import { ProcessOrderPage } from '../../pages/ProcessOrderPage';
import { LoginPage } from '../../pages/LoginPage';
import { LogoutPage } from '../../pages/LogoutPage';
import { SelectLocationPage } from '../../pages/SelectLocationPage';
import { SelectUserPage } from '../../pages/SelectUserPage';
import { ResetPasswordPage } from '../../pages/ResetPasswordPage';
import { ReportsPage } from '../../pages/ReportsPage';
import { TelehealthPage } from '../../pages/TelehealthPage';
import { UnknownPage } from '../../pages/UnknownPage';
import { UserPage } from '../../pages/UserPage';
import { ShipmentHistoryPage } from '../../pages/ShipmentHistoryPage';
import { ScriptQueuePage } from '../../pages/ScriptQueuePage';
import { ScriptProcessPage } from '../../pages/ScriptProcessPage';
import { TemplatePage } from '../../pages/TemplatePage';

import { UserTimeoutModal } from '../../components/UserTimeoutModal/UserTimeoutModal';
import { Splash } from "../Splash/Splash";
import { IntercomMessenger } from "../IntercomMessenger/IntercomMessenger";

export default function App(): React.ReactElement {
  const [loggedinStatus, setLoggedinStatus] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [partner, setPartner] = useState<GetCurrentPartnerResponse | null>(null);
  const [location, setLocation] = useState<any | null>(null);
  // const [selectedUser, setSelectedUser] = useState<any | undefined>(null);

  // FOR DEV
  // const [loggedinStatus, setLoggedinStatus] = useState(true);
  // const [user, setUser] = useState<IUser | null>(dummyUser);
  // const [location, setLocation] = useState<any | null>({ name: 'Smith\'s Pharmacy, York Street' });
  const [selectedUser, setSelectedUser] = useState<any | undefined>({ name: 'Hamish', partner_id: 3 });

  const [orders, setOrders] = useState<IOrders | null>(null);
  const [carts, setCarts] = useState<Array<Cart> | null>(null);
  const [scriptQueueOrders, setScriptQueueOrders] = useState<Array<QueueElementWithItems> | null>(null);
  const [partnerLocations, setPartnerLocations] = useState<ILocations | null>(null);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);

  const [showSplash, setShowSplash] = useState(true);

  const [scriptQueueConfig, setScriptQueueConfig] = useState<IScriptQueueConfig>(defaultScriptQueueConfig);

  const handleLogin = (user: IUser | null, partner: GetCurrentPartnerResponse | null, partnerLocations: ILocations | null) => {
    setUser(user);
    setPartner(partner);
    setLoggedinStatus(true);

    if (user && partnerLocations) {
      // retrieve dispatch location from local storage
      const storedLocation = localStorage.getItem('Scription_dispatchLocation');
      if (storedLocation !== null) {
        const storedPartnerId = parseInt(storedLocation.split('_')[0]);
        const storedCode = storedLocation.split('_')[1];
        // apply stored location if partner id matches current user
        if (storedPartnerId === user.partner_id) {
          const location = partnerLocations.find(location => location.code === storedCode);
          if (location) handleSelectLocation(location);
        }
      }
    }
  }

  const handleLogout = () => {
    setLoggedinStatus(false);
    handleSelectLocation(null);
    // setSelectedUser(null);
    setUser(null);
    setPartner(null);
    setOrders(null);
    setCarts(null);
    setScriptQueueOrders(null);
  }

  const handleSelectLocation = (location: ILocation | null) => {
    setLocation(location);
    // setOrders(null);

    // store dispatch location in local storage
    if (location !== null) {
      localStorage.setItem('Scription_dispatchLocation', `${location.partner_id}_${location.code}`);
    } else {
      localStorage.removeItem('Scription_dispatchLocation');
    }
  }

  const handleSelectUser = (user: any) => {
    setSelectedUser(user);
  }

  const handleSetPartnerLocations = (locations: ILocations) => {
    setPartnerLocations(locations);
  }

  const handleTimeoutContinue = (): void => {
    setShowTimeoutModal(false);
  }

  // const transitionDuration = 300;
  const handleTimeoutLogout = (): void => {
    setShowTimeoutModal(false);
    setSelectedUser(undefined);
    // setTimeout(() => { navigate('/select-user') }, transitionDuration);
  }

  useEffect(() => {
    // set background image from local storage if present
    const backgroundClass = localStorage.getItem('Scription_backgroundImage');
    if (backgroundClass !== null && backgroundClass !== undefined) {
      document.body.classList.add(backgroundClass);
    } else {
      // TEMP? set mountain background by default
      const classname = `background_mountains`;
      document.body.classList.add(classname)
      localStorage.setItem('Scription_backgroundImage', classname);
    }
  }, []);

  return (
    <div style={{display:'flex', flexDirection: 'column', minHeight: '100vh'}}>
      <BrowserRouter>
        {/* API Context */}
        <ApiProvider>
          {/* Dummy Data Context */}
          <DummyDataProvider>
            {/* Splash screen for auto login */}
            <Splash show={showSplash} />

            <Routes>
              {/* Home page -- currently unused, redirects to Orders page */}
              <Route path="/" element={<HomePage loggedin={loggedinStatus} user={user} location={location} selectedUser={selectedUser} onSelectLocation={handleSelectLocation} onSelectUser={handleSelectUser} onLogout={handleLogout} />} />

              {/* Login page */}
              <Route path="/login" element={<LoginPage loggedin={loggedinStatus} user={user} location={location} partnerLocations={partnerLocations || undefined} selectedUser={selectedUser} onLogin={handleLogin} onLogout={handleLogout} onSelectLocation={handleSelectLocation} onSetPartnerLocations={handleSetPartnerLocations} onSetShowSplash={setShowSplash} />} />

              {/* Select User page */}
              <Route path="/select-user" element={<SelectUserPage loggedin={loggedinStatus} user={user} location={location} selectedUser={selectedUser} onSelectUser={handleSelectUser} onLogout={handleLogout} />} />

              {/* Select Location page */}
              <Route path="/select-store" element={<SelectLocationPage loggedin={loggedinStatus} user={user} location={location} partnerLocations={partnerLocations || undefined} selectedUser={selectedUser} onSelectLocation={handleSelectLocation} onLogout={handleLogout} />} />

              {/* Logout page */}
              <Route path="/logout/:auto?" element={<LogoutPage loggedin={loggedinStatus} user={user} onLogout={handleLogout} onSetShowSplash={setShowSplash} />} />

              {/* Reset Password page */}
              <Route path="/reset-password/:confirm?" element={<ResetPasswordPage loggedin={loggedinStatus} user={user} onLogin={handleLogin} onLogout={handleLogout} />} />

              {/* User page */}
              <Route path="/u/:userId" element={<UserPage loggedin={loggedinStatus} user={user} location={location} onSelectLocation={handleSelectLocation} onSelectUser={handleSelectUser} onLogout={handleLogout} />} />

              {/* Orders page */}
              <Route path="/send/orders/:categoryParam?" element={<OrdersPage orders={orders} setOrders={setOrders} carts={carts} setCarts={setCarts} loggedin={loggedinStatus} user={user!} location={location} partner={partner} onSelectLocation={handleSelectLocation} partnerLocations={partnerLocations} onSelectUser={handleSelectUser} onLogout={handleLogout} />} />

              {/* Process Order page */}
              <Route path="/send/process/:process/:orderId?" element={<ProcessOrderPage loggedin={loggedinStatus} user={user} partner={partner} location={location} onSelectLocation={handleSelectLocation} onSelectUser={handleSelectUser} onLogout={handleLogout} orders={orders!} partnerLocations={partnerLocations!} />} />
              
              {/* Process Order page (duplicate of above with SparrowPhone path) */}
              <Route path="/phone/process/:process/:orderId?" element={<ProcessOrderPage loggedin={loggedinStatus} user={user} partner={partner} location={location} onSelectLocation={handleSelectLocation} onSelectUser={handleSelectUser} onLogout={handleLogout} orders={orders!} partnerLocations={partnerLocations!} />} />

              {/* Shipment History page */}
              <Route path="/send/shipments" element={<ShipmentHistoryPage loggedin={loggedinStatus} user={user} location={location} onSelectLocation={handleSelectLocation} onSelectUser={handleSelectUser} onLogout={handleLogout} />} />

              {/* Admin Settings page */}
              <Route path="/admin" element={<AdminPage loggedin={loggedinStatus} user={user} location={location} onSelectLocation={handleSelectLocation} onSelectUser={handleSelectUser} onLogout={handleLogout} />} />

              {/* Performance Reports page */}
              <Route path="/insight" element={<ReportsPage loggedin={loggedinStatus} user={user} location={location} onSelectLocation={handleSelectLocation} onSelectUser={handleSelectUser} onLogout={handleLogout} />} />

              {/* Telehealth page */}
              <Route path="/consult" element={<TelehealthPage loggedin={loggedinStatus} user={user} location={location} onSelectLocation={handleSelectLocation} onSelectUser={handleSelectUser} onLogout={handleLogout} />} />
              
              {/* Paper Scripts Archived Orders page */}
              <Route path="/queue/archived" element={<ScriptQueuePage archived config={scriptQueueConfig} orders={scriptQueueOrders} setOrders={setScriptQueueOrders} loggedin={loggedinStatus} user={user} location={location} onSelectLocation={handleSelectLocation} onSelectUser={handleSelectUser} onLogout={handleLogout} />} />

              {/* Paper Scripts Queue page */}
              <Route path="/queue/tickets/:categoryParam?" element={<ScriptQueuePage config={scriptQueueConfig} orders={scriptQueueOrders} setOrders={setScriptQueueOrders} loggedin={loggedinStatus} user={user} location={location} onSelectLocation={handleSelectLocation} onSelectUser={handleSelectUser} onLogout={handleLogout} />} />

              {/* Paper Scripts Process page */}
              <Route path="/queue/process/:process/:orderId?" element={<ScriptProcessPage config={scriptQueueConfig} orders={scriptQueueOrders!} setOrders={setScriptQueueOrders} loggedin={loggedinStatus} user={user} location={location} partner={partner!} onSelectLocation={handleSelectLocation} onSelectUser={handleSelectUser} onLogout={handleLogout} partnerLocations={partnerLocations!} />} />

              {/* 404 */}
              <Route path="*" element={<UnknownPage loggedin={loggedinStatus} user={user} onSelectLocation={handleSelectLocation} onSelectUser={handleSelectUser} onLogout={handleLogout} onSetShowSplash={setShowSplash} />} />

              {/* temp scription white label templates */}
              <Route path="/whitelabel/:id" element={<TemplatePage />} />
            </Routes>
          </DummyDataProvider>
        </ApiProvider>

        <IntercomMessenger user={user} />
      </BrowserRouter>

      <UserTimeoutModal show={showTimeoutModal} currentUser={selectedUser} onContinue={handleTimeoutContinue} onLogout={handleTimeoutLogout} />
    </div>
  );
}
