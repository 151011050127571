import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { transitions, colours, boxShadows } from '../../assets/css/variables';

import { Checkbox } from '../Checkbox/Checkbox';

import dropdownIcon from '../../assets/images/icons/Dropdown.svg';


export type FilterDropdownItem = {
  label: string
  onClick: Function
  isActive?: boolean
  childOptions?: Array<FilterDropdownItem>
}

type FilterDropdownProps = {
  id: string
  label: string
  items: Array<FilterDropdownItem>
  isActive?: boolean
  setDropdownOpen?: Function
  onClear: Function
  isRadio?: boolean
}

export const FilterDropdown: FunctionComponent<FilterDropdownProps> = ({ id, label, items, isActive, setDropdownOpen, onClear, isRadio }) => {
  const [open, setOpen] = useState(false);
  
  // use useEffect to add and remove mouse event listeners on mount/unmount
  useEffect(() => {
    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    }
  })

  const toggleOpen = (): void => {
    setOpen(!open);
    if (setDropdownOpen) setDropdownOpen(!open);
  }

  const handleOnClick = (onClick: Function): void => {
    onClick();
    // setOpen(false);
    if (setDropdownOpen) setDropdownOpen(false);
  }
  
  const handleOnClear = (onClear: Function): void => {
    onClear();
    setOpen(false);
    if (setDropdownOpen) setDropdownOpen(false);
  }

  const handleClickOutside = (event: PointerEvent): void => {
    if (menuRef && !menuRef.contains(event.target as Node) && open) {
      setOpen(false);
      if (setDropdownOpen) setDropdownOpen(false);
    }
  }

  let menuRef: HTMLDivElement | null;
  const initClickOutside = (el: HTMLDivElement | null): void => {
    if (el) {
      menuRef = el;
    }
  }

  return (
    <StyledFilterDropdown ref={el => initClickOutside(el)}>
      <div className={`FilterDropdown_button ${isActive && 'active'}`} onClick={toggleOpen}>
        <label className="bold">{label}</label>
        <img src={dropdownIcon} alt="" />
      </div>
      
      <div className={`FilterDropdown ${open ? 'open' : 'closed'} ${isRadio ? 'radioButton' : ''}`}>
        <div className="FilterDropdown_heading">
          <p className="heading bold pSmall">Filter by {label}</p>
          <p className="link" onClick={() => handleOnClear(onClear)}>Clear</p>
        </div>
        <ul className="FilterDropdown_items">
          {items.map((item, index) => {
            return (
              <div key={`${id}-${index}`}>
                {/* parent option */}
                <div className="FilterDropdown_item" key={`${id}-${index}`} onClick={() => handleOnClick(item.onClick)}>
                  <div className="FilterDropdown_inner">
                    <Checkbox
                      id={`${id}-${index}-${index}`}
                      selected={item.isActive || false}
                      readonly
                    >
                      <span>{item.label}</span>
                    </Checkbox>
                  </div>
                </div>

                {/* child options */}
                {item.childOptions && item.childOptions.map((childItem, childIndex) => {
                  return (
                    <div className="FilterDropdown_item childItem" key={`${id}-${index}-${childIndex}`} onClick={() => handleOnClick(childItem.onClick)}>
                      <div className="FilterDropdown_inner">
                        <Checkbox
                          id={`${id}-${index}-${childIndex}`}
                          selected={childItem.isActive || false}
                          readonly
                        >
                          <span>{childItem.label}</span>
                        </Checkbox>
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </ul>
      </div>
    </StyledFilterDropdown>
  );
}

const StyledFilterDropdown = styled.div`
  position: relative;
  width: fit-content;

  .FilterDropdown_button {
    position: relative;
    width: fit-content;
    height: 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 33px 0 12px ;
    background: white;
    border: 1px solid white;

    cursor: pointer;
    transition: box-shadow ${transitions.default};
    ${boxShadows.default}

    &:hover {
      ${boxShadows.hover}
    }

    label {
      font-size: 0.75rem; // 12px
      color: #9b9b9b;
      cursor: pointer;
      user-select: none;
    }

    img {
      width: 9px;
      height: auto;
      opacity: 0.4;
      position: absolute;
      right: 10px;
    }

    &.active {
      border-color: black;

      label {
        color: black;
      }

      img {
        opacity: 1;
      }
    }
  }

  .FilterDropdown {
    position: absolute;
    left: 0;
    top: 40px;
    overflow: hidden;
    user-select: none;
    z-index: 99;
    transition: 
      opacity ${transitions.default},
      height ${transitions.slow};
    
    background: white;
    border-radius: 6px;
    box-shadow: 0 0 5px 0 rgba(180, 180, 180, 0.25);
    padding: 14px 0 0 0;

    &.open {
      opacity: 1;
    }

    &.closed {
      opacity: 0;
      pointer-events: none;
    }

    .FilterDropdown_heading {
      display: flex;
      justify-content: space-between;
      padding: 0 14px;
      position: relative;

      .heading {
        margin-top: 0;
        margin-bottom: 14px;
      }

      .link {
        font-family: 'Mulish Medium';
        font-size: 0.625rem; // 10px
        color: #61C6FF;
        text-decoration: underline;
        cursor: pointer;
        position: absolute;
        top: -4px;
        right: 8px;
        margin-top: 0;
        padding: 6px;
      }
    }

    .FilterDropdown_items {
      padding: 0;
      margin: 0;
      min-width: 235px;
      max-height: 225px;
      min-height: 65px;
      overflow-y: auto;

      .FilterDropdown_item {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 0 14px;
        cursor: pointer;
        transition: background-color ${transitions.default};

        &:hover {
          font-family: "Mulish SemiBold";
          background-color: ${colours.neutral400};
        }

        &.childItem {
          padding-left: 42px;
        }

        .FilterDropdown_inner {
          pointer-events: none;
        }
      }
    }
  }

  .radioButton {
    input {
      border-radius: 100%;
    }
  }
`