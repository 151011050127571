import { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { boxShadows, transitions } from '../../assets/css/variables';

import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { resetState, setCreatedAt, setDashboardVisibility, setItemTypes, setRequiresCounselling } from '../../store/queueFilterSlice';
import { QueueItemTypes } from 'sparrowhub-client-axios';

import { DropdownMenu, DropdownMenuItem } from '../DropdownMenu/DropdownMenu';
import { FilterDropdown, FilterDropdownItem } from '../FilterDropdown/FilterDropdown';


type QueueFiltersProps = {
}

export const QueueFilters: FunctionComponent<QueueFiltersProps> = () => {
  const dispatch = useAppDispatch();
  const queueFilterState = useAppSelector((state) => state.queueFilter);

  // methods
  const clearFilters = (): void => {
    dispatch(resetState());
  }
  
  const handleToggleItemTypes = (includedTypes: Array<QueueItemTypes>): void => {
    let mutatedItemTypes = JSON.parse(JSON.stringify(queueFilterState.itemTypes.includedTypes));
    if (mutatedItemTypes === null) {
      mutatedItemTypes = includedTypes;
    } else {
      if (includedTypes.every(type => mutatedItemTypes!.includes(type))) {
        // remove all
        includedTypes.forEach(typeToRemove => {
          const indexToRemove = mutatedItemTypes!.findIndex((val: QueueItemTypes) => val === typeToRemove);
          mutatedItemTypes!.splice(indexToRemove, 1);
        })
      } else {
        // add all (if not present)
        includedTypes.forEach(typeToAdd => {
          const shouldAdd = mutatedItemTypes!.findIndex((val: QueueItemTypes) => val === typeToAdd) === -1;
          if (shouldAdd) mutatedItemTypes.push(typeToAdd);
        })
      }
    }

    // reset to null if empty
    if (mutatedItemTypes.length === 0) mutatedItemTypes = null;

    dispatch(setItemTypes({ includedTypes: mutatedItemTypes }));
  }

  const handleToggleDashboardVisibility = (state: 'hidden' | 'visible'): void => {
    if (queueFilterState.dashboardVisibilty.includedStates === state) {
      dispatch(setDashboardVisibility({ includedStates: null }));
    } else {
      dispatch(setDashboardVisibility({ includedStates: state }));
    }
  }
  
  const handleToggleRequiresCounselling = (state: boolean): void => {
    if (queueFilterState.requiresCounselling.includedStates === state) {
      dispatch(setRequiresCounselling({ includedStates: null }));
    } else {
      dispatch(setRequiresCounselling({ includedStates: state }));
    }
  }

  const isActive = (includedTypes: Array<QueueItemTypes>): boolean => {
    if (queueFilterState.itemTypes.includedTypes === null) {
      return false;
    } else {
      return includedTypes.every(type => queueFilterState.itemTypes.includedTypes?.includes(type));
    }
  }

  // filter options
  const itemTypesFilterOptions: Array<FilterDropdownItem> = [
    {
      label: 'All scripts',
      onClick: () => handleToggleItemTypes([QueueItemTypes.ErxPaperScript, QueueItemTypes.Escript, QueueItemTypes.PaperScript]),
      isActive: isActive([QueueItemTypes.ErxPaperScript, QueueItemTypes.Escript, QueueItemTypes.PaperScript]),
      childOptions: [
        {
          label: 'Paper scripts',
          onClick: () => handleToggleItemTypes([QueueItemTypes.PaperScript]),
          isActive: isActive([QueueItemTypes.PaperScript]),
        },
        {
          label: 'eRx paper scripts',
          onClick: () => handleToggleItemTypes([QueueItemTypes.ErxPaperScript]),
          isActive: isActive([QueueItemTypes.ErxPaperScript]),
        },
        {
          label: 'E-scripts',
          onClick: () => handleToggleItemTypes([QueueItemTypes.Escript]),
          isActive: isActive([QueueItemTypes.Escript]),
        },
      ]
    },
    {
      label: 'All services',
      onClick: () => handleToggleItemTypes([QueueItemTypes.GeneralConsult, QueueItemTypes.MedicalCertificate, QueueItemTypes.ScopeOfPractice, QueueItemTypes.Vaccination]),
      isActive: isActive([QueueItemTypes.GeneralConsult, QueueItemTypes.MedicalCertificate, QueueItemTypes.ScopeOfPractice, QueueItemTypes.Vaccination]),
      childOptions: [
        {
          label: 'General consults',
          onClick: () => handleToggleItemTypes([QueueItemTypes.GeneralConsult]),
          isActive: isActive([QueueItemTypes.GeneralConsult]),
        },
        {
          label: 'Vaccinations',
          onClick: () => handleToggleItemTypes([QueueItemTypes.Vaccination]),
          isActive: isActive([QueueItemTypes.Vaccination]),
        },
        {
          label: 'Medical certificates',
          onClick: () => handleToggleItemTypes([QueueItemTypes.MedicalCertificate]),
          isActive: isActive([QueueItemTypes.MedicalCertificate]),
        },
        {
          label: 'Scope of practice',
          onClick: () => handleToggleItemTypes([QueueItemTypes.ScopeOfPractice]),
          isActive: isActive([QueueItemTypes.ScopeOfPractice]),
        }
      ]
    },
  ]

  const dashboardVisibilityFilterOptions: Array<FilterDropdownItem> = [
    {
      label: 'Visible on dashboard',
      onClick: () => handleToggleDashboardVisibility('visible'),
      isActive: queueFilterState.dashboardVisibilty.includedStates === 'visible',
    },
    {
      label: 'Hidden from dashboard',
      onClick: () => handleToggleDashboardVisibility('hidden'),
      isActive: queueFilterState.dashboardVisibilty.includedStates === 'hidden',
    },
  ]
  
  const requiresCounsellingFilterOptions: Array<FilterDropdownItem> = [
    {
      label: 'Counselling required',
      onClick: () => handleToggleRequiresCounselling(true),
      isActive: queueFilterState.requiresCounselling.includedStates === true,
    },
    {
      label: 'Counselling not required',
      onClick: () => handleToggleRequiresCounselling(false),
      isActive: queueFilterState.requiresCounselling.includedStates === false,
    },
  ]

  return (
    <StyledQueueFilters className="QueueFilters">
      {/* <div className="QueueFilters_filter">
        <label className="bold">Date</label>
      </div> */}

      <FilterDropdown id="itemType" label="Item Type" items={itemTypesFilterOptions} isActive={queueFilterState.itemTypes.includedTypes !== null} onClear={() => dispatch(setItemTypes({ includedTypes: null }))} />
      <FilterDropdown id="dashboardVisibility" label="Dashboard Visibility" items={dashboardVisibilityFilterOptions} isActive={queueFilterState.dashboardVisibilty.includedStates !== null} onClear={() => dispatch(setDashboardVisibility({ includedStates: null }))} isRadio />
      <FilterDropdown id="requiresCounselling" label="Requires Counselling" items={requiresCounsellingFilterOptions} isActive={queueFilterState.requiresCounselling.includedStates !== null} onClear={() => dispatch(setRequiresCounselling({ includedStates: null }))} isRadio />
    </StyledQueueFilters>
  );
}

const StyledQueueFilters = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  margin-top: 21px;

  .QueueFilters_filter {
    position: relative;
    width: fit-content;
    height: 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 33px 0 12px ;
    background: white;
    cursor: pointer;
    transition: box-shadow ${transitions.default};
    ${boxShadows.default}

    &:hover {
      ${boxShadows.hover}
    }

    label {
      font-size: 0.75rem; // 12px
      color: #9b9b9b;
      cursor: pointer;
      user-select: none;

      &.active {
        color: black;
      }
    }
  }
`