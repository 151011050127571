import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { QueueElement, QueueElementWithItems, QueueItemTypes } from 'sparrowhub-client-axios'
import { ticketIsHiddenAutomatically } from '../helpers/utils'

// ----------------------------------------------------------- TYPES

export interface DateFilter {
  startDate: number | null
  endDate: number | null
}

export interface ItemTypesFilter {
  includedTypes: Array<QueueItemTypes> | null
}

export interface DashboardVisibilityFilter {
  includedStates: 'visible' | 'hidden' | null
}

export interface BasicBooleanFilter {
  includedStates: boolean | null
}

export interface QueueFilterState {
  createdAt: DateFilter
  itemTypes: ItemTypesFilter
  dashboardVisibilty: DashboardVisibilityFilter
  requiresCounselling: BasicBooleanFilter
}

export interface IIndexable<T = any> {
  [key: string]: T
}

// ----------------------------------------------------------- INITIAL STATE

const initialState: QueueFilterState = {
  createdAt: {
    startDate: null,
    endDate: null
  },
  itemTypes: {
    includedTypes: null
  },
  dashboardVisibilty: {
    includedStates: null
  },
  requiresCounselling: {
    includedStates: null
  }
}

// ----------------------------------------------------------- UTIL METHODS

export const filterQueueElements = (elements: Array<QueueElementWithItems>, state: QueueFilterState): Array<QueueElementWithItems> => {
  return elements.filter(el => {
    // handle createdAt
    // TODO
    
    // handle itemTypes
    if (state.itemTypes.includedTypes !== null) {
      if (el.queue_items === undefined) {
        return false;
      } else if (!el.queue_items.some(item => state.itemTypes.includedTypes!.includes(item.queue_item_type_code))) {
        return false;
      }
    }

    // handle dashboardVisibility
    if (state.dashboardVisibilty.includedStates !== null) {
      if (
        (state.dashboardVisibilty.includedStates === 'hidden' && (el.is_on_dashboard === true && !ticketIsHiddenAutomatically(el))) ||
        (state.dashboardVisibilty.includedStates === 'visible' && (el.is_on_dashboard === false || ticketIsHiddenAutomatically(el)))
      ) {
        return false;
      }
    }
    
    // handle requiresCounselling
    if (state.requiresCounselling.includedStates !== null) {
      if (
        (state.requiresCounselling.includedStates === true && el.requires_consultation === false) ||
        (state.requiresCounselling.includedStates === false && el.requires_consultation === true)
      ) {
        return false;
      }
    }

    // if it hasn't failed any of the filters, return true
    return true;
  })
}

// ----------------------------------------------------------- SLICE

export const queueFilterSlice = createSlice({
  name: 'queueFilter',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.createdAt = initialState.createdAt;
    },
    setCreatedAt: (state, action: PayloadAction<Partial<DateFilter>>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        (state.createdAt as IIndexable)[key] = value;
      }
    },
    setItemTypes: (state, action: PayloadAction<ItemTypesFilter>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        (state.itemTypes as IIndexable)[key] = value;
      }
    },
    setDashboardVisibility: (state, action: PayloadAction<DashboardVisibilityFilter>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        (state.dashboardVisibilty as IIndexable)[key] = value;
      }
    },
    setRequiresCounselling: (state, action: PayloadAction<BasicBooleanFilter>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        (state.requiresCounselling as IIndexable)[key] = value;
      }
    },
  },
})

export const {
  resetState,
  setCreatedAt,
  setItemTypes,
  setDashboardVisibility,
  setRequiresCounselling
} = queueFilterSlice.actions

export default queueFilterSlice.reducer
