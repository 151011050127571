import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe, StripeElements } from '@stripe/stripe-js';

import App from './components/App/App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './assets/css/style.scss';

import { store } from './store'
import { Provider } from 'react-redux'

declare global {
  var grecaptcha: any;
  interface Window {
    cordova: any;
    stripe: Stripe | null;
    stripeElements: StripeElements | null;
  }
}

if (process.env.REACT_APP_STRIPE_KEY !== undefined) {
  loadStripe(process.env.REACT_APP_STRIPE_KEY).then(result => {
    window.stripe = result;
  })
} else {
  console.warn('No Stripe key found, unable to init Stripe.')
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
