import { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { ProcessQueueElementUpdateRequest, QueueElement, SparrowHubApiInterface } from 'sparrowhub-client-axios';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { TextArea } from '../TextArea/TextArea';
import { DefaultErrorMessage } from '../DefaultErrorMessage/DefaultErrorMessage';
import { Badge } from '../Badge/Badge';
import { ScriptDetails } from '../ScriptDetails/ScriptDetails';


type ScriptQueueNotesModalProps = {
  show: boolean
  order: QueueElement
  onClose: Function
  onRefresh: Function
}

export const ScriptQueueNotesModal: FunctionComponent<ScriptQueueNotesModalProps> = ({ show, order, onClose, onRefresh }) => {
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();

  // state
  const [initialValue, setInitialValue] = useState('');
  const [noteInput, setNoteInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  // set initial values
  useEffect(() => {
    const noteString = !order.queue_note ? '' : order.queue_note;
    setInitialValue(noteString);
    setNoteInput(noteString);
  }, [ show ]);

  // methods
  const saveChanges = (): void => {
    setErrorMessage('');
    handleUpdateNote();
  }

  const handleUpdateNote = (): void => {
    setIsLoading(true);
    setErrorMessage('');
    const requestBody: ProcessQueueElementUpdateRequest = {
      queue_note: noteInput
    }

    api.processQueueElementUpdate(order.id, requestBody)
      .then(response => {
        setIsLoading(false);

        if (response.data && response.data.status === 'success') {
          onRefresh();
          setTimeout(() => { onClose() }, 300);
        }
      })
      .catch(error => {
        setIsLoading(false);
        setErrorMessage(`Error updating ticket note.`);
        console.log(error)
      })
  }

  const validChanges = (): boolean => {
    return noteInput.trim() !== initialValue.trim();
  }

  return (
    <StyledScriptQueueNotesModal className="OrderNotes">
      <Modal show={show}>
        <Heading heading="Ticket Notes" />
        <div className="OrderNotes_details">
          <div className="Order_badgeContainer">
            <Badge type="scriptQueue_inStore" />
            {order.requires_consultation &&
              <Badge type="scriptQueue_requiresCounselling" />
            }
            {!order.is_on_dashboard &&
              <Badge type="scriptQueue_hiddenDashboard" />
            }
          </div>
          <ScriptDetails order={order} />
        </div>

        <TextArea id="notes-input" label={`${initialValue === '' ? 'Add' : 'Edit'} ticket note`} value={noteInput} maxLength={512} small onChange={(e: ChangeEvent) => setNoteInput((e.target as HTMLTextAreaElement).value)} />

        {errorMessage &&
          <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
            <p>{errorMessage}<br/><DefaultErrorMessage /></p>
          </Alert>
        }

        <Button type={ButtonType.Primary} text="Save Changes" onClick={saveChanges} disabled={!validChanges()} loading={isLoading} />
        <Button type={ButtonType.Secondary} text="Cancel" onClick={() => onClose()} />
      </Modal>
    </StyledScriptQueueNotesModal>
  );
}

const StyledScriptQueueNotesModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    /* .Badge {
      position: absolute;
      top: 60px;
      right: 50px;
    } */
    
    .OrderNotes_details {
      margin-top: 28px;
      margin-bottom: 38px;

      .Badge {
        display: inline-flex;
        margin-right: 8px;
      }
    }

    .OrderNotes_existing {
      textarea {
        margin-bottom: 0px;
      }

      .OrderNotes_note {
        padding: 8px;
        margin: 10px 0;
        border-radius: 4px;
        background: #DADADA;
        p {
          margin: 0;
          font-size: 0.875rem; // 14px
          word-wrap: break-word;
        }
      }
    }

    .Button_primary {
      margin-top: 34px;
      margin-bottom: 23px;
    }
  }
`