import { FunctionComponent } from 'react';
import styled from 'styled-components';


import { QueueElement, QueueItemTypes } from "sparrowhub-client-axios";
import { formatOrderAddress, getOrderNumber } from '../../helpers/utils';

import phoneIcon from '../../assets/images/icons/Phone.svg';
import personIcon from '../../assets/images/icons/Person.svg';
import paperScriptIcon from '../../assets/images/icons/Prescriptions.svg';
import erxScriptIcon from '../../assets/images/icons/Erx.svg';
import eScriptIcon from '../../assets/images/icons/Escript.svg';
import shoppingBagIcon from '../../assets/images/icons/ShoppingBag.svg';
import consultIcon from '../../assets/images/icons/ConsultLarge.svg';
import vaccinationIcon from '../../assets/images/icons/Syringe.svg';

type ScriptDetailsProps = {
  order: QueueElement
}

export const ScriptDetails: FunctionComponent<ScriptDetailsProps> = ({ order }) => {
  const numItemsEachType = () => {
    if (!order.queue_items) return [0, 0, 0, 0, 0, 0, 0];

    return [
      order.queue_items.filter(item => item.queue_item_type_code === QueueItemTypes.Escript).length,
      order.queue_items.filter(item => item.queue_item_type_code === QueueItemTypes.ErxPaperScript).length,
      order.queue_items.filter(item => item.queue_item_type_code === QueueItemTypes.PaperScript).length,
      order.queue_items.filter(item => item.queue_item_type_code === QueueItemTypes.GeneralConsult).length,
      order.queue_items.filter(item => item.queue_item_type_code === QueueItemTypes.MedicalCertificate).length,
      order.queue_items.filter(item => item.queue_item_type_code === QueueItemTypes.ScopeOfPractice).length,
      order.queue_items.filter(item => item.queue_item_type_code === QueueItemTypes.Vaccination).length,
    ]
  }

  const scriptCountLabel = (count: number, typeIndex: number): string => {
    let label = '';

    switch (typeIndex) {
      case 0:
        label = 'e-script';
        break;
      case 1:
        label = 'eRx script';
        break;
      case 2:
        label = 'paper script';
        break;
      case 3:
        label = 'general consult';
        break;
      case 4:
        label = 'med cert';
        break;
      case 5:
        label = 'scope of practice';
        break;
      case 6:
        label = 'vaccination';
        break;
    }

    if (count !== 1) label += 's';

    return label;
  }

  const scriptCountIcon = (typeIndex: number): string => {
    switch (typeIndex) {
      case 0:
        return eScriptIcon;
      case 1:
        return erxScriptIcon;
      case 2:
        return paperScriptIcon;
      case 3:
        return consultIcon;
        break;
      case 4:
        return consultIcon;
        break;
      case 5:
        return consultIcon;
        break;
      case 6:
        return vaccinationIcon;
        break;
      default:
        return '';
    }
  }

  return (
    <StyledScriptDetails className="ScriptDetails divider">
      {/* name, if provided */}
      <p className="ScriptDetails_id bold">
        {order.customer_first_name &&
          <>
            <span className="ScriptDetails_name ellipsis">{order.customer_first_name}</span>
            <span className="ScriptDetails_divider">|</span>
          </>
        }
        <span className="ScriptDetails_number bold">Ticket {order.queue_element_number}</span>
      </p>

      
      <div className="ScriptDetails_contact">
        {/* contact information */}
        {!order.customer_first_name && !order.customer_phone && !order.customer_email ?
          <p className="ScriptDetails_detail">
            <img src={phoneIcon} alt="Phone" />
            No contact details
          </p>
        :
          <>
            {order.customer_phone &&
              <p className="ScriptDetails_detail">
                <img src={phoneIcon} alt="Phone" />
                {order.customer_phone}
              </p>
            }
          </>
        }

        {/* script information */}
        {numItemsEachType().map((count: number, i: number) => {
          if (count !== 0) {
            return (
              <p className="ScriptDetails_detail" key={`numScripts-${order.id}-${i}`}>
                <img className="ScriptDetails_icon" src={scriptCountIcon(i)} alt="Paper Scripts" />
                {count} {scriptCountLabel(count, i)}
              </p>
            )
          } else {
            return null
          }
        })}
      </div>
    </StyledScriptDetails>
  );
}

const StyledScriptDetails = styled.div`
  margin-bottom: 20px;

  .ScriptDetails_id {
    margin: 13px 0 10px 0;
    font-size: 1.125rem; // 18px
    width: calc(100% - 40px);
    white-space: nowrap;
    display: flex;

    .ScriptDetails_name {
    }

    .ScriptDetails_number {
      min-width: fit-content;
    }

    .ScriptDetails_divider {
      flex-grow: 0;
      margin: 0 12px;
    }
  }

  .ScriptDetails_contact {
    /* display: flex; */
    width: 100%;
    margin-top: 15px;
    margin-bottom: 6px;

    p {
      margin-top: 0;
    }
  }

  .ScriptDetails_detail {
    display: inline-flex;
    align-items: center;
    font-size: 1rem; // 16px
    margin: 10px 0;

    &:not(:last-child) {
      margin-right: 30px;
    }

    &.ScriptDetails_email {
      flex-grow: 1;
      max-width: 60%;
      /* max-width: 200px; */
    }
    
    /* &.ScriptDetails_address {
      margin-top: 10px !important;
    } */

    img {
      margin-right: 5px;
      width: 17px;
      height: auto;
    }
  }
`