import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useDummyData } from '../../context/DummyDataProvider';

import { IScriptQueueConfig } from '../../types/IScriptQueueConfig';
import { formatTimestamp, ticketContainsServices, ticketContainsScripts } from '../../helpers/utils';
import { QueueElement, QueueItem, QueueItemTypes } from 'sparrowhub-client-axios';

import { ScriptData } from '../ScriptData/ScriptData';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';

import chemistworksLogo from '../../assets/images/logos/Chemistworks.png';
import catClaudiaLogo from '../../assets/images/logos/logo-catandclaudias.png';
import smithsLogo from '../../assets/images/logos/PlaceholderPharmacy.svg';
import southcityLogo from '../../assets/images/logos/logo-southcity.png';
import pricelineLogo from '../../assets/images/logos/logo-priceline-rounded.png';
import terryWhiteLogo from '../../assets/images/logos/TerryWhite_Chemmart_logo.png';
import baronePharmacyLogo from '../../assets/images/logos/BaronePharmacy.png';

import phoneIcon from '../../assets/images/icons/Phone.svg';
import paperScriptIcon from '../../assets/images/icons/Prescriptions.svg';
import erxScriptIcon from '../../assets/images/icons/Erx.svg';
import eScriptIcon from '../../assets/images/icons/Escript.svg';
import consultIcon from '../../assets/images/icons/ConsultLarge.svg';
import vaccinationIcon from '../../assets/images/icons/Syringe.svg';

export enum ScriptDocketType {
  Customer = 'customer',
  Pharmacist = 'pharmacist'
}

const queueItemOptions: Array<any> = [
  {
    label: 'Paper Script',
    code: QueueItemTypes.PaperScript
  },
  {
    label: 'eRx Paper Script',
    code: QueueItemTypes.ErxPaperScript
  },
  {
    label: 'E-Script',
    code: QueueItemTypes.Escript
  },
  {
    label: 'General Consult',
    code: QueueItemTypes.GeneralConsult
  },
  {
    label: 'Vaccination',
    code: QueueItemTypes.Vaccination
  },
  {
    label: 'Medical Certificate',
    code: QueueItemTypes.MedicalCertificate
  },
  {
    label: 'Scope Of Practice',
    code: QueueItemTypes.ScopeOfPractice
  }
]


type ScriptDocketProps = {
  order: QueueElement | any
  type: ScriptDocketType
  config: IScriptQueueConfig
}

export const ScriptDocket: FunctionComponent<ScriptDocketProps> = ({ order, type, config }) => {
  const dummyData: any = useDummyData();

  // computed
  const partnerLogo = (): string => {
    if (dummyData.state.useDummyData) {
      return dummyData.state.dummyLogo;
    } else {
      switch (order.partner_id) {
        case 3:
          return chemistworksLogo;
        case 4:
          return catClaudiaLogo;
        case 5:
        case 6:
          return southcityLogo;
        case 7: // Baldwin Dev
        case 8: // Smith's Pharmacy
          return smithsLogo;
        case 11:
          return pricelineLogo;
        case 16:
          return terryWhiteLogo;
        case 18:
          return baronePharmacyLogo;
        default:
          return '';
      }
    }
  }

  const partnerName = (): string => {
    if (dummyData.state.useDummyData) {
      return dummyData.state.dummyLocation.name;
    } else {
      switch (order.partner_id) {
        case 3:
          return 'Chemistworks';
        case 4:
          return 'Cat & Claudia\'s Community Pharmacy';
        case 5:
        case 6:
          return 'Southcity Pharmacy';
        case 7: // Baldwin Dev
        case 8: // Smith's Pharmacy
          return 'Smith\'s Pharmacy';
        case 11:
          return 'Priceline Pharmacy';
        case 16:
          return 'TerryWhite Chemmart';
        case 18:
          return 'Barone Pharmacy';
        default:
          return '';
      }
    }
  }

  const escripts = () => {
    return order.escripts || order.queue_items ? order.queue_items.filter((item: QueueItem) => item.queue_item_type_code === QueueItemTypes.Escript) : [];
  }

  const erxScripts = () => {
    return order.erxScripts || order.queue_items ? order.queue_items.filter((item: QueueItem) => item.queue_item_type_code === QueueItemTypes.ErxPaperScript) : [];
  }
  
  const paperScripts = () => {
    return order.paperScripts || order.queue_items ? order.queue_items.filter((item: QueueItem) => item.queue_item_type_code === QueueItemTypes.PaperScript) : [];
  }

  const services = () => {
    return order.queue_items ?
        order.queue_items.filter((item: QueueItem) => (
          item.queue_item_type_code === QueueItemTypes.GeneralConsult ||
          item.queue_item_type_code === QueueItemTypes.MedicalCertificate ||
          item.queue_item_type_code === QueueItemTypes.ScopeOfPractice ||
          item.queue_item_type_code === QueueItemTypes.Vaccination
        )) : [];
  }

  const numScriptsEachType = () => {
    if (order.id && order.queue_items) {  
      return [
        order.queue_items.filter((item: QueueItem) => item.queue_item_type_code === QueueItemTypes.Escript).length,
        order.queue_items.filter((item: QueueItem) => item.queue_item_type_code === QueueItemTypes.ErxPaperScript).length,
        order.queue_items.filter((item: QueueItem) => item.queue_item_type_code === QueueItemTypes.PaperScript).length,
        order.queue_items.filter((item: QueueItem) => item.queue_item_type_code === QueueItemTypes.GeneralConsult).length,
        order.queue_items.filter((item: QueueItem) => item.queue_item_type_code === QueueItemTypes.MedicalCertificate).length,
        order.queue_items.filter((item: QueueItem) => item.queue_item_type_code === QueueItemTypes.ScopeOfPractice).length,
        order.queue_items.filter((item: QueueItem) => item.queue_item_type_code === QueueItemTypes.Vaccination).length,
      ]
    } else if (!order.queue_items) {
      return [0, 0, 0, 0, 0, 0, 0];
    } else {
      return [
        escripts().length,
        erxScripts().length,
        paperScripts().length
      ]
    }
  }

  const scriptCountLabel = (count: number, typeIndex: number): string => {
    let label = '';

    switch (typeIndex) {
      case 0:
        label = 'e-script';
        break;
      case 1:
        label = 'eRx script';
        break;
      case 2:
        label = 'paper script';
        break;
      case 3:
        label = 'general consult';
        break;
      case 4:
        label = 'med cert';
        break;
      case 5:
        label = 'scope of practice';
        break;
      case 6:
        label = 'vaccination';
        break;
    }

    if (count !== 1) label += 's';

    return label;
  }

  const scriptCountIcon = (typeIndex: number): string => {
    switch (typeIndex) {
      case 0:
        return eScriptIcon;
      case 1:
        return erxScriptIcon;
      case 2:
        return paperScriptIcon;
      case 3:
        return consultIcon;
        break;
      case 4:
        return consultIcon;
        break;
      case 5:
        return consultIcon;
        break;
      case 6:
        return vaccinationIcon;
        break;
      default:
        return '';
    }
  }

  const getQueueItemLabel = (script: any): string => {
    const option = queueItemOptions.find(option => option.code === script.queue_item_type_code);
    
    if (option) {
      return option.label;
    } else {
      return 'Service';
    }
  }

  return (
    <StyledScriptDocket className="ScriptDocket">
      {type === ScriptDocketType.Customer &&
        <div className="ScriptDocket_customer">
          <img className="ScriptDocket_logo" src={partnerLogo()} alt="" />
          <p className="ScriptDocket_location bold">Welcome to {partnerName()}</p>
          <p className="ScriptDocket_orderIdIntro">Your ticket number is:</p>
          <p className="ScriptDocket_orderId bold">{order.queue_element_number}</p>
          {order.customer_first_name &&
            <p className="ScriptDocket_customer bold">{order.customer_first_name}</p>
          }
          {/* Contains both scripts and services */}
          {(ticketContainsServices(order) && ticketContainsScripts(order)) &&
            <p className="ScriptDocket_message">
              We are currently working on fulfilling your prescription.<br /><br />
              Please continue to monitor the screen to see when your prescription is ready for pickup. If you supplied a phone number, you will also receive a text message when your prescription is ready for collection.<br /><br />
              Please present this docket when you return to the scripts counter.
            </p>
          }
          {/* Contains services only */}
          {(ticketContainsServices(order) && !ticketContainsScripts(order)) &&
            <p className="ScriptDocket_message">
              A pharmacist will be available for you soon.<br /><br />
              Please continue to monitor the screen to see when your ticket number is displayed. If you supplied a phone number, you will also receive a text message when a pharmacist is ready for you.<br /><br />
              Please present this docket when you return to the scripts counter.
            </p>
          }
          {/* Contains scripts only */}
          {(!ticketContainsServices(order) && ticketContainsScripts(order)) &&
            <p className="ScriptDocket_message">
              We are currently working on fulfilling your prescription.<br /><br />
              Please continue to monitor the screen to see when your prescription is ready for pickup. If you supplied a phone number, you will also receive a text message when your prescription is ready for collection.<br /><br />
              Please present this docket when you return to the scripts counter.
            </p>
          }
          <p className="ScriptDocket_timestamp">{formatTimestamp(order.created_at, 'datetime12')}</p>
        </div>
      }

      {type === ScriptDocketType.Pharmacist &&
        <div className="ScriptDocket_pharmacist">
          <h2 className="ScriptDocket_customerName">{order.customer_first_name}</h2>
          <h2 className="ScriptDocket_queueElementNumber">{order.queue_element_number}</h2>
          {order.requires_consultation &&
            <Alert type={AlertType.Important} icon={AlertIcon.ExclamationRedAlt}>
              <p>Pharmacist counselling required</p>
            </Alert>
          }
          {order.queue_note &&
            <div className="ScriptDocket_orderNotes">
              <p className="bold heading">Order Notes</p>
              <p className="content">{order.queue_note}</p>
            </div>
          }

          <div className="ScriptDocket_details">
            <p className="ScriptDetails_detail">
              <img className="ScriptDetails_icon" src={phoneIcon} alt="Phone" />
              {order.customer_phone || 'No contact details'}
            </p>
          </div>
          <div className="ScriptDocket_details scriptDetails">
            {numScriptsEachType().map((count: number, i: number) => {
              if (count !== 0) {
                return (
                  <p className="ScriptDetails_detail" key={`numScripts-${order.id}-${i}`}>
                    <img className="ScriptDetails_icon" src={scriptCountIcon(i)} alt="" />
                    {count} {scriptCountLabel(count, i)}
                  </p>
                )
              } else {
                return null
              }
            })}
          </div>
          <p className="ScriptDocket_timestamp">{formatTimestamp(order.created_at, 'datetime12')}</p>

          {escripts().length > 0 &&
            <div className="ScriptDocket_scriptSection">
              <h3>E-Scripts</h3>
              {escripts().map((script: any) => {
                return (
                  <ScriptData script={script} key={`escript-${script.id}`} docket />
                )
              })}
            </div>
          }

          {erxScripts().length > 0 &&
            <div className="ScriptDocket_scriptSection">
              <h3>eRx Paper Scripts</h3>
              {erxScripts().map((script: any) => {
                return (
                  <ScriptData script={script} key={`erxscript-${script.id}`} docket />
                )
              })}
            </div>
          }

          {paperScripts().length > 0 &&
            <div className="ScriptDocket_scriptSection">
              <h3>Paper Scripts</h3>
              {paperScripts().map((script: any) => {
                return (
                  <div key={`paperScript-${script.id}`}>
                    <p className="paperScripts bold">Paper Script</p>
                    <div className="ScriptDocket_orderNotes paperScriptNote">
                      <p className="bold heading">Script Notes</p>
                      <p className="content">{(script.note || script.queue_item_note) ? 
                        <span>{script.note || script.queue_item_note}</span>
                      :
                        <span className="italic">None</span>
                      }</p>
                    </div>
                  </div>
                )
              })}
            </div>
          }
          
          {services().length > 0 &&
            <div className="ScriptDocket_scriptSection">
              <h3>Services</h3>
              {services().map((script: any) => {
                return (
                  <div key={`service-${script.id}`}>
                    <p className="paperScripts bold">{getQueueItemLabel(script)}</p>
                    {/* <p className="paperScripts bold">Todo service name</p> */}
                    <div className="ScriptDocket_orderNotes paperScriptNote">
                      <p className="bold heading">Service Notes</p>
                      <p className="content">{(script.note || script.queue_item_note) ? 
                        <span>{script.note || script.queue_item_note}</span>
                      :
                        <span className="italic">None</span>
                      }</p>
                    </div>
                  </div>
                )
              })}
            </div>
          }

          {/* {paperScripts().length !== 0 &&
            <div className="ScriptDocket_scriptSection">
              <h3>Paper Scripts</h3>
              <p className="paperScripts bold">{paperScripts().length} x Paper {paperScripts().length === 1 ? 'Script' : 'Scripts'}</p>
              {true &&
                <div className="ScriptDocket_orderNotes paperScriptNote">
                  <p className="bold heading">Script Notes</p>
                  <p className="content">{'test'}</p>
                </div>
              }
            </div>
          } */}
        </div>
      }
    </StyledScriptDocket>
  )
}

const StyledScriptDocket = styled.div`
  /* width: 681px; */
  width: 560px;
  padding: 40px 50px 50px 50px;
  page-break-after: always;
  break-after: always;
  
  .ScriptDocket_customer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .ScriptDocket_logo {
      width: 400px;
      height: 50px;
      object-fit: contain;
      margin: 0 0 52px 0;
    }
    .ScriptDocket_location {
      font-size: 1.375rem; // 22px
      margin: 0 0 35px 0;
    }
    .ScriptDocket_orderIdIntro {
      font-size: 1rem; // 16px
      margin: 0;
    }
    .ScriptDocket_orderId {
      font-size: 4rem; // 64px
      margin: 0 0 40px 0;
    }
    .ScriptDocket_customer {
      font-size: 1.875rem; // 30px
      margin: -20px 0 40px 0;
    }
    .ScriptDocket_message {
      font-size: 0.875rem; // 14px
      line-height: 1.5;
      margin: 0;
    }
    .ScriptDocket_timestamp {
      font-size: 0.75rem; // 12px
      line-height: 1.5;
      margin: 40px 0 -10px 0;
    }
  }

  .ScriptDocket_pharmacist {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 -10px;

    .qrcode {
      svg {
        width: 150px;
        height: 150px;
      }
    }

    h2 {
      font-size: 4rem; // 64px
      margin: 28px 0 20px 0;

      &.ScriptDocket_customerName {
        font-size: 3.125rem; // 50px
        margin-bottom: 0;
      }

      &.ScriptDocket_queueElementNumber {
        font-size: 4rem; // 64px
        margin: 28px 0 20px 0;
      }
    }

    .ScriptDocket_orderNotes {
      margin: 30px 0 20px;
      border: 1px solid black;
      border-radius: 10px;
      padding: 6px 20px;
      width: 90%;

      .heading {
        margin-bottom: 0;
        line-height: 1.625rem !important; // 26px
        font-size: 1.625rem !important; // 26px
      }
      
      .content {
        margin-top: 1rem;
        line-height: 1.625rem !important; // 26px
        font-size: 1.625rem !important; // 26px
      }

      &.paperScriptNote {
        margin: 30px auto 20px auto;
      }
    }

    .Alert {
      margin-bottom: 20px;
      background: #FFEEEE;
      p {
        color: #ED2929;
        font-size: 1.25rem; // 20px
      }
    }

    .ScriptData:not(:last-child) {
      margin-bottom: 100px;
    }

    .ScriptDocket_details {
      display: flex;
      justify-content: center;
      gap: 25px;
      width: 100%;

      .ScriptDetails_detail {
        display: inline-flex;
        align-items: center;
        font-size: 1.125rem; // 18px
        margin: 10px 0;

        img {
          margin-right: 8px;
          width: 26px;
          height: auto;
        }
      }

      &.scriptDetails {
        margin: 5px 0 24px 0;
        flex-direction: row;
      }
    }

    .ScriptDocket_timestamp {
      font-size: 0.875rem; // 14px
      margin-top: 0px;
      margin-bottom: 30px;
    }

    .ScriptDocket_scriptSection {
      width: 100%;
      border-top: 1px solid #DADADA;
      margin-bottom: 100px;

      h3 {
        font-size: 1.5rem; // 24px
        margin: 45px 0;
      }

      .paperScripts {
        font-size: 2.25rem; // 36px
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 40px;
      }
    }
  }
`